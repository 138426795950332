/**
 * Checks if second date is before the first date
 *
 * @param {string} dateString1 - ISO date string (e.g. "2025-01-14T12:14:18.812Z")
 * @param {string} dateString2 - Date in MM-DD-YY format (e.g. "01-10-25")
 * @returns {boolean} - True if second date is before first date, false if both dates exist and second is not before first
 */
export const isBeforeDate = (dateString1, dateString2) => {
  if (!dateString1 || !dateString2) return true;  // Return true if either date is undefined

  const date1 = new Date(dateString1);

  const [month, day, year] = dateString2.split('-');
  const date2 = new Date(2000 + parseInt(year), parseInt(month) - 1, parseInt(day));

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return true;  // Return true if either date is invalid

  return date2 < date1;
};
