import { useContext, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageLoading, setSessionExpired } from '~/redux/actions/common';
import merge from 'lodash/merge';
import { setNotifications } from '~/redux/actions/global';
import { ReduxState } from '~/types/redux';
import { setNewRelicCustomAttribute, setNewRelicNoticeError } from '~/helpers/newrelic';
import { DEFAULT_ERROR_MESSAGE, API_RETRY_MAX, API_RETRY_DELAY } from '~/constants/config/common';
import { getHeaders } from '~/helpers/fetch';
import { PAGE_ROUTES } from '~/constants/pages';
import { FuelContext } from '~/providers/FuelProvider';
import { trackEvent } from '~/helpers/tracking';
import { apiErrorTest, apiErrorTracking } from '~/helpers/apiErrorTest';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import config from '~/constants/config';
import isSessionExpirationError from '~/helpers/sessionExpirationError';

interface State<T> {
  data?: T;
  error?: T | Error;
  loading: boolean;
}

export interface UseFetch<T> {
  data?: T;
  error?: T | Error;
  loading: boolean;
  fetch: (options?: RequestInit) => Promise<T>;
}

type Action<T> = { type: 'loading' } | { type: 'success'; payload: T } | { type: 'error'; payload: T | Error };

function useFetch<T = unknown>(
  slug?: string,
  options?: RequestInit,
  disableLoader?: boolean,
  disableError?: boolean
): UseFetch<T> {
  const { router } = useContext(FuelContext);
  const dispatch = useDispatch();
  const retryCount = useRef(0);
  const { tracking, singleForm } = useSelector((store: ReduxState) => store);
  const defaultOptions = { headers: getHeaders(tracking) };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const creditBypassAttempt = singleForm?.creditBypassAttempt | 0;
  const isBypassError =
    singleForm?.enabled === true && creditBypassAttempt <= 2 && singleForm?.contactCredit !== 'complete';

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    loading: false,
  };

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading': {
        if (!disableLoader) dispatch(setPageLoading(true));
        return { ...initialState, loading: true };
      }
      case 'success': {
        return { ...initialState, loading: false, data: action.payload };
      }
      case 'error': {
        if (!disableLoader) dispatch(setPageLoading(false));
        return { ...initialState, loading: false, error: action.payload };
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatchState] = useReducer(fetchReducer, initialState);

  const fetchData = async (opts?: RequestInit): Promise<T> => {
    dispatchState({ type: 'loading' });
    const retryHeaders = { headers: { retryAttempts: retryCount?.current } };
    try {
      console.log(`${config.api}${slug}`);
      // starting merge with empty object since it merges and updates to first argument
      const response = await fetch(
        `${config.api}${slug}${apiErrorTest('?')}`,
        merge({}, defaultOptions, retryHeaders, options, opts)
      );
      const data = await response?.json();
      if (response.ok) {
        if (!data?.success) {
          handleOrchestrationError(data, router, !disableError);
        } else {
          dispatchState({ type: 'success', payload: data as T });
        }
        apiErrorTracking({ url: response?.url, retryCount: retryCount?.current, success: data?.success });
        setNewRelicCustomAttribute([{ name: 'retryAttempts', value: retryCount?.current || '0' }]);
        retryCount.current = retryCount.current + 1;
        return data;
      } else {
        const isSessionExpired = isSessionExpirationError(data?.frapiErrorCode);
        if (retryCount.current < API_RETRY_MAX && data?.isHardStop && !isSessionExpired) {
          const delay = Math.pow(2, retryCount.current) * API_RETRY_DELAY; // Exponential backoff delay
          await new Promise((resolve) => setTimeout(resolve, delay));

          const token = await executeRecaptcha();
          const retryOpts = JSON.parse(opts.body as string);
          retryOpts.token = token;
          opts.body = JSON.stringify(retryOpts);

          apiErrorTracking({ url: response?.url, retryCount: retryCount?.current, success: false });
          setNewRelicCustomAttribute([{ name: 'retryAttempts', value: retryCount?.current || '0' }]);
          retryCount.current = retryCount.current + 1;
          return fetchData(opts);
        } else {
          dispatch(setPageLoading(false));
          if (!data?.success) {
            handleOrchestrationError(data, router, !disableError);
          }
        }
        if (!data?.errorMessage) {
          const err = new Error(
            `Partner API Failure: status ${response.status} - ${response.url}, no error message found.`
          );
          setNewRelicNoticeError(err);
          trackEvent({
            action: 'clientErrored',
            data: {
              errorMessage: err?.message,
              errorClass: err?.name,
            },
          });
        } else {
          const err = new Error(data?.errorMessage);
          setNewRelicNoticeError(err);
          trackEvent({
            action: 'clientErrored',
            data: {
              errorMessage: err?.message,
              errorClass: err?.name,
            },
          });
        }
        retryCount.current = 0;
      }
    } catch (error) {
      console.log(error); // Log for LogRocket debugging
      dispatch(setPageLoading(false));
      dispatch(setNotifications([{ type: 'error', message: DEFAULT_ERROR_MESSAGE }]));

      setNewRelicNoticeError(error);
      trackEvent({
        action: 'clientErrored',
        data: {
          errorMessage: error?.message,
          errorClass: error?.name,
        },
      });
      retryCount.current = 0;

      const isSessionExpired = isSessionExpirationError(error?.frapiErrorCode);
      if (isSessionExpired) {
        dispatch(setSessionExpired(true));
      }
    }
  };

  const handleOrchestrationError = (data, router, shouldShowError = true) => {
    // Log for LogRocket debugging
    dispatchState({ type: 'error', payload: data as T });

    if (
      (isBypassError || data.userErrorMessage === 'credit-bypass') &&
      !data?.userErrorMessage?.includes('pending order')
    )
      return;

    const isSessionExpired = isSessionExpirationError(data?.frapiErrorCode);

    if (isSessionExpired) {
      // If the error code is FRAPI-205 we're assuming it's because the user's session has expired
      dispatch(setSessionExpired(true));
    } else if (shouldShowError) {
      if (data?.errorMessage?.includes('verification failed')) return;
      if (isBypassError && !data?.userErrorMessage.includes('pending order')) return;
      dispatch(setNotifications([{ type: 'error', message: data?.userErrorMessage || DEFAULT_ERROR_MESSAGE }]));
    }

    if (data?.errorMessage) {
      setNewRelicNoticeError(new Error(data.errorMessage));

      if (data.errorMessage.includes('Credit failed with a score of' && data.userErrorMessage !== 'credit-bypass')) {
        if (isBypassError || data.frapiErrorCode === 'FRAPI-307') return;
        router?.push(PAGE_ROUTES.POSID_ERROR);
      } else {
        trackEvent({
          action: 'clientErrored',
          data: {
            errorMessage: data?.errorMessage,
            errorClass: data?.errorName,
          },
        });
      }
    }
  };

  return {
    ...state,
    fetch: fetchData,
  };
}

export default useFetch;
