import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '~/components/Button';
import { CloseThin } from '~/components/Icons/fill';
import { ResumeModalStyled } from './ResumeModalStyled';
import { Modals, ResumeQuoteResponse } from '~/types/products';
import ResumeAddress from './Address';
import { setInstallationOptions, setResume, setTracking } from '~/redux/actions/common';
import { FuelContext } from '~/providers/FuelProvider';
import { PAGE_ROUTES } from '~/constants/pages';
import { setAddress } from '~/redux/actions/address';
import useFetch from '~/hooks/useFetch';
import { trackEvent } from '~/helpers/tracking';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';
import Cookies from 'js-cookie';

interface Props {
  setActiveModal: (key?: Modals) => void;
  handleSubmitCommercialModal?: () => void;
  address?: string;
  visible: boolean;
  apiVersion: string;
}

const ResumeModal: FC<Props> = ({ visible, setActiveModal, apiVersion }) => {
  const [loading, setloading] = useState(true);
  const { router } = useContext(FuelContext);
  const dispatch = useDispatch();

  const resumeQuote = useFetch<ResumeQuoteResponse>(
    `${apiVersion}/quote/resume`,
    {
      method: 'GET',
    },
    true
  );

  const handleCancel = () => {
    setActiveModal(undefined);
    window.sessionStorage.setItem('declinedResume', '1');
    dispatch(setResume('acceptedResume', false));
    dispatch(setResume('enabled', false));
    Cookies.set('aiCartExperience', 'false');
    Cookies.set('gdtCartExperience', 'false');
    router.replace(PAGE_ROUTES.ADDRESS_CHECK);
    return;
  };

  const handleContinue = async () => {
    dispatch(setResume('acceptedResume', true));
    dispatch(setResume('enabled', true));
    Cookies.set('resumeEnabled', true);
    window.sessionStorage.setItem('seenResumeModal', 'true');
    router.replace(PAGE_ROUTES.RESUME);
  };

  useEffect(() => {
    setResume('viewedModal', true);
    const resumeStarted = Date.now();
    (async () => {
      const data: ResumeQuoteResponse = await resumeQuote.fetch();

      if (data?.success) {
        window.sessionStorage.setItem('resumeResponse', JSON.stringify(data));
        dispatch(setResume('sweetenerSelected', data?.sweetenerSelected));
        dispatch(setResume('creditChecked', data?.creditChecked));
        dispatch(setResume('cartPage', data?.cartPage));
        dispatch(setTracking('accountUuid', data?.accountUuid));
        dispatch(setTracking('quoteNumber', data?.quoteNumber));
        dispatch(setAddress('install', data?.serviceAddress));
        dispatch(setInstallationOptions('availableInstallOptions', data?.installationOptions));
        setNewRelicCustomAttribute([{ name: 'isResume', value: 'true' }]);
        const timeToFetch = Date.now() - resumeStarted;
        setloading(!loading);
        //Tracks time taken for the address to load
        trackEvent({
          action: 'elementViewed',
          data: {
            elementType: 'MODAL',
            location: 'resume_modal',
            text: `Time taken for address render: ${timeToFetch}`,
          },
        });
      } else {
        setActiveModal(undefined);
        router.replace(PAGE_ROUTES.ADDRESS_CHECK);
      }
    })();
  }, []);

  if (!visible) return null;

  return (
    <ResumeModalStyled>
      <CloseThin onClick={() => handleCancel()} data-testid="modal-btn-close" />
      <div className="resume__modal">
        <header className="resume__header">
          <p className="resume__title">Welcome back. Let's finish your order.</p>
        </header>
        <div className="resume__container" data-testid="resume__container">
          <div className="resume__subtitle">We've saved your information so you can pick up where you left off.</div>
          <ResumeAddress />
          <div className="resume__button-container">
            <Button disabled={loading} className="resume__submit" onClick={() => handleContinue()} data-testid="resume-modal-btn">
              CONTINUE CHECKOUT
            </Button>
          </div>
        </div>
      </div>
    </ResumeModalStyled>
  );
};

ResumeModal.displayName = 'ResumeModal';

export default ResumeModal;
